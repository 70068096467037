import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddLocation.css";

const predefinedCategories = [
  "Crisis & Mental Health",
  "Victim Services",
  "Youth Services",
  "Housing & Transportation",
  "Recreation",
  "Addiction & Treatment",
  "Disability & Senior Services",
  "Veteran Services",
  "Employment & Legal",
  "Clothing & Personal Care",
  "Food",
  "Medical & Dental",
];

function AddLocation() {
  const [formData, setFormData] = useState({
    placeID: "",
    name: "",
    address: "",
    longitude: "",
    latitude: "",
    phoneNumber: "",
    website: "",
    rating: "",
    description: "",
    categories: [],
  });
  const [categoryInput, setCategoryInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false); // Track loading state
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submit state

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "longitude" || name === "latitude"
          ? parseFloat(value) || ""
          : value,
    });
  };

  const handleCategoryInputChange = (e) => {
    const value = e.target.value;
    setCategoryInput(value);

    if (value) {
      const filteredCategories = predefinedCategories.filter((category) =>
        category.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredCategories);
    } else {
      setSuggestions([]);
    }
  };

  const handleCategorySelect = (category) => {
    if (!formData.categories.includes(category)) {
      setFormData((prevState) => ({
        ...prevState,
        categories: [category, ...prevState.categories], // Add the new category at the 0 index
      }));
    }
    setCategoryInput("");
    setSuggestions([]);
  };

  const removeCategory = (category) => {
    setFormData({
      ...formData,
      categories: formData.categories.filter((c) => c !== category),
    });
  };

  const handlePlaceIDChange = async (e) => {
    const placeID = e.target.value;
    setFormData({ ...formData, placeID });
    setIsFetching(true); // Start loading state

    try {
      const response = await axios.get(
        `https://us-central1-nrvcs-389918.cloudfunctions.net/getPlaceDetails?placeID=${placeID}`
      );
      const placeData = response.data.result;

      setFormData({
        ...formData,
        name: placeData.name || "",
        address: placeData.formatted_address || "",
        longitude: placeData.geometry.location.lng || "",
        latitude: placeData.geometry.location.lat || "",
        phoneNumber: placeData.formatted_phone_number || "",
        website: placeData.website || "",
        rating: placeData.rating || "",
        placeID: placeID, // Ensure placeID stays in the form data
      });
      setIsFetching(false); // Stop loading state
    } catch (error) {
      toast.error("Failed to fetch place details.");
      setIsFetching(false); // Stop loading state in case of error
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true); // Start submit state
    try {
      const docRef = doc(db, "locations", formData.name);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        toast.error("A location with this name already exists.");
      } else {
        await setDoc(doc(db, "locations", formData.name), formData);
        toast.success("Location added successfully!");

        setFormData({
          placeID: "",
          name: "",
          address: "",
          longitude: "",
          latitude: "",
          phoneNumber: "",
          website: "",
          rating: "",
          description: "",
          categories: [],
        });
        setCategoryInput("");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Failed to add location. Please try again.");
    }
    setIsSubmitting(false); // Stop submit state
  };

  return (
    <div className="add-location-form">
      <div className="place-id-container">
        <input
          type="text"
          placeholder="Google Place ID"
          name="placeID"
          value={formData.placeID}
          onChange={handlePlaceIDChange}
        />
        {isFetching && <div className="loading-spinner"></div>}
      </div>
      <p className="info-text">
        You can find a place ID by{" "}
        <a
          href="https://developers.google.com/maps/documentation/places/web-service/place-id"
          target="_blank"
          rel="noopener noreferrer"
          className="info-link"
        >
          clicking here
        </a>
        .
      </p>
      <input
        type="text"
        placeholder="Name of the location"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
      />
      <textarea
        placeholder="Address"
        name="address"
        value={formData.address}
        onChange={handleInputChange}
        rows="3"
        style={{ width: "100%" }}
      />
      <input
        type="text"
        placeholder="Longitude"
        name="longitude"
        value={formData.longitude}
        onChange={handleInputChange}
      />
      <input
        type="text"
        placeholder="Latitude"
        name="latitude"
        value={formData.latitude}
        onChange={handleInputChange}
      />
      <input
        type="text"
        placeholder="Phone Number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleInputChange}
      />
      <input
        type="text"
        placeholder="Website"
        name="website"
        value={formData.website}
        onChange={handleInputChange}
      />
      <input
        type="text"
        placeholder="Rating"
        name="rating"
        value={formData.rating}
        onChange={handleInputChange}
        readOnly
      />
      <textarea
        placeholder="Description of the location"
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        rows="3"
        style={{ width: "100%" }}
      />

      <input
        type="text"
        placeholder="Categories (start typing...)"
        value={categoryInput}
        onChange={handleCategoryInputChange}
      />
      <div className="suggestions">
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className="suggestion-item"
            onClick={() => handleCategorySelect(suggestion)}
          >
            {suggestion}
          </div>
        ))}
      </div>

      <div className="category-pills">
        {formData.categories.map((category, index) => (
          <div key={index} className="category-pill">
            {category}
            <span
              className="remove-pill"
              onClick={() => removeCategory(category)}
            >
              x
            </span>
          </div>
        ))}
      </div>

      <button
        onClick={handleSubmit}
        className={`submit-button ${isSubmitting ? "loading" : ""}`}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <div className="submit-loading-spinner"></div>
        ) : (
          "Add Location"
        )}
      </button>
    </div>
  );
}

export default AddLocation;
