import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EditLocation.css";

const predefinedCategories = [
  "Crisis & Mental Health",
  "Victim Services",
  "Youth Services",
  "Housing & Transportation",
  "Recreation",
  "Addiction & Treatment",
  "Disability & Senior Services",
  "Veteran Services",
  "Employment & Legal",
  "Clothing & Personal Care",
  "Food",
  "Medical & Dental",
];

function EditLocation({ setIsTyping }) {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [formData, setFormData] = useState({
    address: "",
    longitude: "",
    latitude: "",
    phoneNumber: "",
    website: "",
    rating: "",
    description: "",
    placeID: "",
    categories: [],
  });
  const [categoryInput, setCategoryInput] = useState("");
  const [isFetching, setIsFetching] = useState(false); // Track loading state

  useEffect(() => {
    const fetchLocations = async () => {
      const querySnapshot = await getDocs(collection(db, "locations"));
      const locationsArray = [];
      querySnapshot.forEach((doc) => {
        locationsArray.push({ id: doc.id, name: doc.data().name });
      });
      setLocations(locationsArray);
    };

    fetchLocations();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsTyping(true);

    if (value) {
      const filteredSuggestions = locations.filter((location) =>
        location.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleLocationSelect = async (location) => {
    setSelectedLocation(location);
    setInputValue(location.name);
    setSuggestions([]);
    setIsFetching(true); // Start loading state

    const docRef = doc(db, "locations", location.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const locationData = docSnap.data();
      setFormData({
        address: locationData.address || "",
        longitude: locationData.longitude || "",
        latitude: locationData.latitude || "",
        phoneNumber: locationData.phoneNumber || "",
        website: locationData.website || "",
        rating: locationData.rating || "",
        description: locationData.description || "",
        placeID: locationData.placeID || "",
        categories: locationData.categories || [],
      });
      setIsFetching(false); // Stop loading state
    } else {
      toast.error("Failed to load location data.");
      setIsFetching(false); // Stop loading state in case of error
    }
  };

  const handleInputChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "longitude" || name === "latitude"
          ? parseFloat(value) || ""
          : value,
    });
  };

  const handleCategoryInputChange = (e) => {
    const value = e.target.value;
    setCategoryInput(value);

    if (value) {
      const filteredCategories = predefinedCategories.filter((category) =>
        category.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredCategories);
    } else {
      setSuggestions([]);
    }
  };

  const handleCategorySelect = (category) => {
    if (!formData.categories.includes(category)) {
      setFormData({
        ...formData,
        categories: [...formData.categories, category],
      });
    }
    setCategoryInput("");
    setSuggestions([]);
  };

  const removeCategory = (category) => {
    setFormData({
      ...formData,
      categories: formData.categories.filter((c) => c !== category),
    });
  };

  const handleSubmit = async () => {
    if (selectedLocation) {
      try {
        const docRef = doc(db, "locations", selectedLocation.id);
        await updateDoc(docRef, formData);
        toast.success("Location updated successfully!");

        setSelectedLocation(null);
        setFormData({
          address: "",
          longitude: "",
          latitude: "",
          phoneNumber: "",
          website: "",
          rating: "",
          description: "",
          placeID: "",
          categories: [],
        });
        setCategoryInput("");
        setInputValue("");
      } catch (error) {
        console.error("Error updating document: ", error);
        toast.error("Failed to update location. Please try again.");
      }
    } else {
      toast.error("Please select a location to edit.");
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search for a location..."
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className="suggestions">
        {suggestions.map((suggestion) => (
          <div
            key={suggestion.id}
            className="suggestion-item"
            onClick={() => handleLocationSelect(suggestion)}
          >
            {suggestion.name}
          </div>
        ))}
      </div>

      {selectedLocation && (
        <div className="edit-form">
          <input
            type="text"
            placeholder={isFetching ? "loading..." : "Google Place ID"}
            name="placeID"
            value={formData.placeID}
            onChange={handleInputChangeForm}
            disabled={isFetching}
          />
          <textarea
            placeholder="Address"
            name="address"
            value={formData.address}
            onChange={handleInputChangeForm}
            rows="3"
            style={{ width: "100%" }}
            disabled={isFetching}
          />
          <input
            type="text"
            placeholder="Longitude"
            name="longitude"
            value={formData.longitude}
            onChange={handleInputChangeForm}
            disabled={isFetching}
          />
          <input
            type="text"
            placeholder="Latitude"
            name="latitude"
            value={formData.latitude}
            onChange={handleInputChangeForm}
            disabled={isFetching}
          />
          <input
            type="text"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChangeForm}
            disabled={isFetching}
          />
          <input
            type="text"
            placeholder="Website"
            name="website"
            value={formData.website}
            onChange={handleInputChangeForm}
            disabled={isFetching}
          />
          <input
            type="text"
            placeholder="Rating"
            name="rating"
            value={formData.rating}
            onChange={handleInputChangeForm}
            disabled={isFetching}
          />
          <textarea
            placeholder="Description of the location"
            name="description"
            value={formData.description}
            onChange={handleInputChangeForm}
            rows="3"
            style={{ width: "100%" }}
            disabled={isFetching}
          />

          {/* Categories input with suggestions */}
          <input
            type="text"
            placeholder="Categories (start typing...)"
            value={categoryInput}
            onChange={handleCategoryInputChange}
            disabled={isFetching}
          />
          <div className="suggestions">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => handleCategorySelect(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>

          {/* Selected categories */}
          <div className="category-pills">
            {formData.categories.map((category, index) => (
              <div key={index} className="category-pill">
                {category}
                <span
                  className="remove-pill"
                  onClick={() => removeCategory(category)}
                >
                  x
                </span>
              </div>
            ))}
          </div>

          <button
            onClick={handleSubmit}
            className="submit-button"
            disabled={isFetching}
          >
            Update Location
          </button>
        </div>
      )}
    </div>
  );
}

export default EditLocation;
